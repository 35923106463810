<template>
  <div class="content-detail-wrap flex">
    <Left v-if="!isfoldLeft" id="left-wrap" class="left" :order-information="orderInformation" :title-type="titleType" />
    <div :class="['middle card-padding', isfoldLeft ? 'middle-all' : 'middle-half']">
      <div id="scroll-wrap" ref="scrollWrap" class="scroll-wrap">
        <div v-if="countInfo.messageCount" class="task-tip"><i class="iconfont icon-information-line" />您有 {{ countInfo.messageCount||0 }} 条改派/取消信息待处理</div>
        <div class="card-title flex-c">
          <span>{{ titleType }}详情</span>
          <i class="el-icon-refresh" @click="refreshLineNum" />
          <img class="cursor" src="@/assets/images/common/tansport.svg">
        </div>
        <div class="time-line-scroll flex">
          <Timeline id="line-date" ref="timeline" :key="topComponentKey" class="line-date" :order-id="Number(orderId)" :type="+orderInformation.type===1?1.1:1.2" />
        </div>
        <div class="transportation-num">
          <TotalNum :key="topComponentKey" type="2" :order-id="Number(orderId)" />
        </div>
        <div class="table-wrap">
          <div class="arrow" :class="isfoldLeft ? 'arrow-left' : 'arrow1'" @click="foldLeft">
            <i :class="isfoldLeft ? 'el-icon-arrow-right' : 'el-icon-arrow-left'" />
            <span v-if="isfoldLeft">{{ titleType }}信息</span>
          </div>
          <div class="tabs-wrap">

            <Mtabs :tabs="tabs" class="tabs" :init-tab-index="activeTabIndex" @changeTab="changeTab" @refresh="refreshData" />
          </div>
          <div class="tab-down-wrap">
            <HasConfirm v-if="activeTabIndex === 0" :key="componentKey" :order-id="Number(orderId)" />
            <HasCancel v-if="activeTabIndex ===1" :key="componentKey" :order-id="Number(orderId)" />
            <ReassignAndCancel v-if="activeTabIndex === 2" :key="componentKey" :order-id="Number(orderId)" :show-action="true" :order-information="orderInformation" type="CYHD" @actionSuccess="getCount" />
          </div>
        </div>
      </div>
      <transition name="fade">
        <div v-show="showBackTop" class="back-to-top" @click="backToTop">
          <i class="el-icon-top" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import supplyTask from '@/api/goodsOrder/supplyTask';
import Left from '@/components/OrderBusiness/left';
import Timeline from '@/components/OrderBusiness/timeline';
import TotalNum from '@/components/OrderBusiness/totalNum';
import Mtabs from '@/components/OrderBusiness/Mtabs';
import HasConfirm from './components/hasConfirm';
import HasCancel from './components/hasCancel';
import ReassignAndCancel from '@/views/goodsOrder/components/detail/reassignAndCancel';
export default {
  components: { Left, Timeline, TotalNum, Mtabs, HasConfirm, HasCancel, ReassignAndCancel },
  data() {
    return {
      titleType: '货源',
      topComponentKey: 1,
      componentKey: 1,
      orderId: '',
      orderInformation: {}, // 货单信息
      activeTabIndex: 0,
      tabs: [
        { label: '已确认', value: 0 },
        { label: '已取消（货主确认后的取消车辆）', value: 1 },
        { label: '改派/取消', value: 2 },
      ],
      isfoldLeft: false, // 左侧是否展开铺满
      showBackTop: false,
      countInfo: {
        messageCount: 0, // 通知消息统计
        cancelCount: 0, // 取消改派统计
      },
    };
  },
  computed: {
  },
  created() {
    this.orderId = +this.$route.query.id;
    this.activeTabIndex = +this.$route.query.tabIndex || 0;
    this.getDetail();
    this.getCount();
  },
  mounted() {
    const box = this.$refs.scrollWrap;
    box.addEventListener(
      'scroll',
      () => {
        this.showBackTop = this.$refs.scrollWrap.scrollTop > 215;
      },
      false,
    );
  },
  methods: {
    // 货单信息+货单详情时间轴
    async getDetail() {
      const detail = await supplyTask.orderGoodsDetail(this.orderId).then((res) => {
        return res || {};
      });
      this.orderInformation = detail;
    },
    getCount() {
      supplyTask.orderGoodsRecordXwCount({ id: this.orderId }).then(res => {
        this.countInfo = res || {};
      }).finally(() => {
        this.tabs = [
          { label: '已确认', value: 0 },
          { label: '已取消（货主确认后的取消车辆）', value: 1 },
          { label: '改派/取消', value: 2, taskNum: this.countInfo.cancelCount },
        ];
      });
    },
    // 左侧展开
    foldLeft() {
      this.isfoldLeft = !this.isfoldLeft;
    },
    // 切换tab
    changeTab(data) {
      this.activeTabIndex = Number(data.value);
      // 暂无多余tab接入 暂时写法 后期提炼
      this.componentKey = this.componentKey + 1;
      this.getCount();
    },
    refreshLineNum() {
      this.topComponentKey = this.topComponentKey + 1;
    },
    // tab下重新刷新tab数据
    refreshData() {
      this.componentKey = this.componentKey + 1;
      this.getCount();
      // 改派取消模式下2需要刷新detail接口 便于获得最新的state用于展示取消该派操作
      if (this.activeTabIndex === 2) {
        this.getDetail();
      }
    },
    backToTop() {
      const container = document.querySelector('#scroll-wrap');
      container.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    // 跳转运力监控
    openMap(data) {
      if (data.id) {
        this.$router.push(`/transport/monitor?id=${data.id}`);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/goodsOrder.scss";
</style>
